<template>
    <footer>
      <div class="footer-container">
        <p>&copy; 2024 TWM Egypt</p>
        <ul>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Contact Us</a></li>
          <router-link to="/terms-of-use">Terms Of Use</router-link>
        </ul>
      </div>
    </footer>
  </template>
  
  <script>
  export default{
      name:'FooterBar'
  }
  </script>
  
<style scoped>
  footer {
    background-color: #332C29;
    width: 100%;
    max-height: 50vh;
    color: #fff;
    text-align: center;
    padding: 1em;
   
  
  }

  .footer-container {
    width: 100%;
  padding: 1em;
  display: flex;
  justify-content: space-evenly ;
  }

  .footer-container p {
    margin: 0;
  }

  footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  footer li {
    margin-right: 20px;
  }

  footer a {
    color: #fff;
    text-decoration: none;
    transition: color 0.2s ease;
  }

  footer a:hover {
    color: #ccc;
  }

  footer p {
    font-size: 14px;
  }
</style>