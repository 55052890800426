<template>
    <nav>
    <router-link to="/">Home</router-link> 
    <router-link to="/about">About</router-link>
    <router-link to="/gallery">Gallery</router-link>
    <router-link to="/contact-us">Contact Us</router-link>
  </nav>
</template>


<script>
export default {
  name: 'NavBar'
}
</script>
 
<style scoped>

nav {
  width: 100%;
  padding: 1em;
  display: flex;
  justify-content: flex-end ;
  
}

nav a {
  font-weight: bold;
  color: #332C29;
  margin: 5px 50px;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #F46626;
}
</style>


