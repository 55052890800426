<template>
  <NavBar/>
  <router-view/>
  <FooterBar/>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterBar from './components/Footer.vue';
export default {
  components:{
    NavBar,
    FooterBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
