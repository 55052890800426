<template>
  <div class="hello">
    <div class="title">
      <img src="@/assets/images/logo.png" alt="twm logo">
      <div class="vl"></div>
      <h1>BUILT TO LAST</h1>
      <div class="background-image" style="background-image: url('your-image-url-here')"></div>
    </div>
    <div class="services">
      <h1>Our Services</h1>
      <div class="cards-container">
        <div class="card" v-for="card in cards" :key="card.id">
          <img :src="card.image" alt="card image">
          <h3>{{ card.title }}</h3>
          <p>{{ card.description }}</p>
        </div>
      </div>
    </div>
    <div class="info-container">
      <div class="info">
        <h2>Our Mission</h2>
        <p>"At TWM, our mission is to deliver exceptional welding and steel structure solutions that exceed our customers' expectations. We are committed to providing high-quality, precision-crafted products and services that meet the most stringent industry standards. Our team of skilled professionals is dedicated to building long-term relationships with our clients, understanding their unique needs, and delivering tailored solutions that enhance their operations and success."</p>
        <router-link to="/about">Read more</router-link>
      </div>
      <img src="@/assets/images/gallery/1643968347565.jpg" alt="mission image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      cards: [
        { id: 2, image:require('@/assets/images/cards/card2.png'), title:'Plasma and Flame Cutting', description: 'We offer precise plasma and flame cutting services for various metals, delivering accurate cuts and shapes to meet your project requirements.' },
        { id: 1, image:require('@/assets/images/cards/card1.png'), title:'Welding', description: 'Our expert welders provide high-quality MIG, TIG, and MAG welding services for a variety of metals and applications, ensuring strong and durable bonds.' },
        { id: 3, image:require('@/assets/images/cards/card3.png'), title:'Pipe Line Fabrication', description: 'Our team specializes in pipe line fabrication, providing custom solutions for oil and gas, water treatment, and other industries, ensuring safe and efficient operations.' },
        { id: 4, image:require('@/assets/images/cards/card4.png'), title:'3D Printing', description: 'Our experienced designers create custom machinery designs that meet your specific needs, improving productivity and reducing costs.' },
        { id: 5, image:require('@/assets/images/cards/card5.png'), title:'Steel Structure', description: 'We design and fabricate steel structures for various applications, including buildings, bridges, and industrial facilities, ensuring strength, durability, and safety.' },
      ]
    }
  }
}
</script>

<style scoped>
.title{
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 4em;
  max-height: 500px;
  margin: 0 3em;
  align-items: center ;
  position: relative;
}
.title img{
  max-width: 100px;
  max-height: 100px;
  margin: 1em;
}
.title h1{
  margin: 1em;
  color: #2b2a2f;
}
.vl {
  border-left: 6px solid #CA6D42;
  height: 100px;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: url('');
  background-size: cover;
}
.services{
  margin: 200px 0;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
  }

  .card {
    margin: 20px;
    width: 200px;
    text-align: center;
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px 18px #888888;
    border-radius: 25px 1px 25px 1px;
    padding: 1em;
  }

  .card img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  .card p {
    padding: 10px;
    text-align: left;
  }

  /* alternate image and text position */
  .card:nth-child(odd) img {
    order: 1;
  }

  .card:nth-child(odd) h3, .card:nth-child(odd) p {
    order: 2;
  }

  .card:nth-child(even) img {
    order: 2;
  }

  .card:nth-child(even) h3, .card:nth-child(even) p {
    order: 1;
  }
  .info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px;
  }

  .info {
    flex-basis: 50%;
  }

  .info-container img {
    flex-basis: 40%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  .info p{
    text-align: left;
  }
  .info a{
    text-decoration: none;
    font-weight: bold;
    color: #332C29;
    
  }
</style>
